




























import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { isMainCourseLanguage, isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { validateBoolean, validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { UpsertCurriculumDto } from "@/store/modules/plans/interfaces/UpsertCurriculumDto.interface";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "OptionalInformation",
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore<StoreState>();
    const route = useRoute();
    const curriculumValues = useAutoDeepCloneProp<UpsertCurriculumDto>(props, "value");
    const isMainCourseVocationalSchool = isVocationalSchool(+route.query.mainCourseId);

    const emitChanges = () => {
      emit("input", deepCloneObject(curriculumValues.value));
    };
    return {
      curriculumValues,
      validateNotEmpty,
      validateBoolean,
      emitChanges,
      isMainCourseVocationalSchool,
      isMainCourseLanguage: isMainCourseLanguage(+route.query.mainCourseId),
      defaultOptions: computed(() => store.state.plans.defaultOptions),
    };
  },
});
