

















import { ApiGetNusCodeDto } from "@/api/generated/Api";
import CurriculumInfo from "@/components/curriculum/new-steps/general-info/CurriculumInfo.vue";
import CurriculumSpecific from "@/components/curriculum/new-steps/general-info/CurriculumSpecific.vue";
import Hours from "@/components/curriculum/new-steps/general-info/Hours.vue";
import Name from "@/components/curriculum/new-steps/general-info/Name.vue";
import OptionalInformation from "@/components/curriculum/new-steps/general-info/OptionalInformation.vue";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { isMainCourseLanguage, isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { useRoute } from "@/shared/useHelpers";
import { UpsertCurriculumDto } from "@/store/modules/plans/interfaces/UpsertCurriculumDto.interface";
import { defineComponent, PropType } from "@vue/composition-api";
import Vue from "vue";

export default defineComponent({
  name: "GeneralInfoStep",
  components: {
    Name,
    CurriculumInfo,
    OptionalInformation,
    Hours,
    CurriculumSpecific,
  },
  props: {
    value: {
      type: Object as PropType<UpsertCurriculumDto>,
      required: true,
    },
    nusCodes: {
      type: Array as PropType<ApiGetNusCodeDto[]>,
      required: true,
    },
  },
  setup(props, context) {
    const route = useRoute();

    const curriculumValues = useAutoDeepCloneProp<UpsertCurriculumDto>(props, "value");

    const validate = () => {
      const isValid = (context.refs.form as Vue & { validate: () => boolean }).validate();
      if (isValid) {
        context.emit("input", deepCloneObject(curriculumValues.value));
        return true;
      }
      return false;
    };

    return {
      curriculumValues,
      isMainCourseLanguage: isMainCourseLanguage(+route.query.mainCourseId),
      isVocationalSchool: isVocationalSchool(+route.query.mainCourseId),
      isNew: route.path.includes("ny"),
      validate,
    };
  },
});
