
























import CurriculumSection from "@/components/curriculum/CurriculumSection.vue";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { useRoute } from "@/shared/useHelpers";
import { UpsertCurriculumDto } from "@/store/modules/plans/interfaces/UpsertCurriculumDto.interface";
import { defineComponent } from "@vue/composition-api";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History, // @ts-ignore TODO : Typescriptiong.
} from "tiptap-vuetify";
import Vue from "vue";

export default defineComponent({
  name: "LearningRequirementsStep",
  components: { TiptapVuetify, CurriculumSection },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const route = useRoute();
    const curriculumValues = useAutoDeepCloneProp<UpsertCurriculumDto>(props, "value");

    const validate = () => {
      const isValid = (context.refs.form as Vue & { validate: () => boolean }).validate();
      if (isValid) {
        context.emit("input", deepCloneObject(curriculumValues.value));
        return true;
      }
      return false;
    };
    return {
      curriculumValues,
      validate,
      isVocationalSchool: isVocationalSchool(+route.query.mainCourseId),
    };
  },
  data: () => ({
    values: {},
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
  }),
});
