















































import { ApiGetCustomerDto, ApiGetNusCodeDto } from "@/api/generated/Api";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { UpsertCurriculumDto } from "@/store/modules/plans/interfaces/UpsertCurriculumDto.interface";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, PropType } from "@vue/composition-api";
export default defineComponent({
  name: "CurriculumSpecific",
  props: {
    value: {
      type: Object,
      required: true,
    },
    nusCodes: {
      type: Array as PropType<ApiGetNusCodeDto[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore<StoreState>();
    const route = useRoute();
    const curriculumValues = useAutoDeepCloneProp<UpsertCurriculumDto>(props, "value");

    const isMainCourseVocationalSchool = isVocationalSchool(+route.query.mainCourseId);

    const organizationItem = (organization: ApiGetCustomerDto) =>
      `${organization.customer?.name} - ${organization.customer?.orgNumber}`;

    const nusCodeItem = (nusCode: ApiGetNusCodeDto) => `${nusCode.code} - ${nusCode.name}`;

    const emitChanges = () => {
      emit("input", deepCloneObject(curriculumValues.value));
    };

    return {
      curriculumValues,
      organizationItem,
      validateNotEmpty,
      emitChanges,
      defaultOptions: computed(() => store.state.plans.defaultOptions),
      contactOrganizations: computed(() =>
        store.state.contacts.organisations.filter((organisation) => organisation.customer.isActive)
      ),
      nusCodeItem,
      isMainCourseVocationalSchool,
    };
  },
});
