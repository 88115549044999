




































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "BaseStepper",
  props: {
    stepNames: {
      type: Array,
    },
    conditionalSteps: {
      type: Array,
      required: false,
    },
    postBtn: {
      type: String,
      required: false,
    },
    isPost: {
      type: Boolean,
      default: false,
    },
    draftBtn: {
      type: String,
      required: false,
    },
    isDraft: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  }, // base form stepper? TODO
  data() {
    return {
      currentStep: 1,
    };
  },
  computed: {
    steps(): any {
      return Object.keys(this.$scopedSlots).length;
    },
  },
  methods: {
    getSlotName(idx: any) {
      return Object.keys(this.$scopedSlots)[idx];
    },
    async nextStep(newId: any) {
      if (newId === this.steps) {
        this.currentStep = 1;
      } else {
        await this.$emit("next", newId);
        if (this.isValid) {
          this.currentStep = newId + 1;
        }
      }
    },
    previouStep(n: any) {
      this.currentStep = n - 1;
    },
    cancelStepper() {
      this.$emit("cancel");
    },
    postAction() {
      this.$emit("post");
    },
    draftAction() {
      this.$emit("draft");
    },
  },
});
