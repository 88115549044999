


















import { mapActions } from "vuex";

import BaseStepper from "@/components/shared/stepper/BaseStepper.vue";

import GeneralInfoStep from "@/components/curriculum/new-steps/GeneralInfoStep.vue";
import LearningRequirementsStep from "@/components/curriculum/new-steps/LearningRequirementsStep.vue";
import TargetAudienceStep from "@/components/curriculum/new-steps/TargetAudienceStep.vue";
import LearningObjectivesStep from "@/components/curriculum/new-steps/LearningObjectivesStep.vue";
import CourseDescriptionStep from "@/components/curriculum/new-steps/CourseDescriptionStep.vue";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "EditImplementationPlanPage",
  components: {
    BaseStepper,
    GeneralInfoStep,
    LearningRequirementsStep,
    TargetAudienceStep,
    LearningObjectivesStep,
    CourseDescriptionStep,
  }, // TODO correct name?
  data() {
    return {
      stepNames: ["Generell Info", "Lærerkrav", "Målgruppe", "Læringsmål", "Beskrivelse", "Bevis"],
    };
  },
  methods: {
    ...mapActions("plans", ["fetchCurriculum", "fetchDefaultOptions"]),
    cancel() {
      this.$router.push(`/home/plansforApproval`);
      return true;
    },
    post() {
      this.$router.push(`/home/gjennomforingsplaner/ny/${this.$route.params.id}`);
    },
  },
  async mounted() {
    await (this as any).fetchDefaultOptions();
    await (this as any).fetchCurriculum(this.$route.params.id);
  },
});
