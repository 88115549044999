










































import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "CurriculumInfo",
  setup() {
    const store = useStore<StoreState>();
    const route = useRoute();

    const courseAreaItems = computed(() => {
      if (+route.query.mainCourseId !== 0 && store.state.plans.defaultOptions.courseAreas) {
        const area = store.state.plans.defaultOptions.courseAreas.find(
          (obj: any) => obj.id === +route.query.mainCourseId
        );
        return area?.children;
      }
      return [];
    });

    return { courseAreaItems, validateNotEmpty, defaultOptions: computed(() => store.state.plans.defaultOptions) };
  },
});
