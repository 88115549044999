

































import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { TeachingMethods } from "@/shared/enums/teaching-methods.enum";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { UpsertCurriculumDto } from "@/store/modules/plans/interfaces/UpsertCurriculumDto.interface";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "Hours",
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore<StoreState>();
    const curriculumValues = useAutoDeepCloneProp<UpsertCurriculumDto>(props, "value");
    const teachingMethod = computed(() => curriculumValues.value.teachingMethod || "");
    const isOnlineHoursRequired = computed(
      () =>
        ![TeachingMethods.Klasserom, TeachingMethods.VirtueltKlasserom].includes(
          teachingMethod.value.toLocaleLowerCase() as TeachingMethods
        )
    );

    const emitChanges = () => {
      emit("input", deepCloneObject(curriculumValues.value));
    };
    return {
      curriculumValues,
      validateNotEmpty,
      emitChanges,
      defaultOptions: computed(() => store.state.plans.defaultOptions),
      isOnlineHoursRequired,
    };
  },
});
